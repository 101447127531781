import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { addClass, removeClass } from 'app/utils/domHelpers';
import mediaQueries from 'app/utils/mediaQueries';

class MiniDetails extends Component {
  constructor() {
    super();

    this.isLargeUp = mediaQueries.is_large_up();

    this.miniDetailsRef = React.createRef();
    this.miniDetailsWrapperRef = React.createRef();

    this.resizeHandler = this.resizeHandler.bind(this);
  }

  componentDidMount() {
    const { setMiniDetailsWrapperRef, backdrop, closeButton, closeDetails } = this.props;

    backdrop.addEventListener('click', closeDetails);
    closeButton.addEventListener('click', closeDetails);
    setMiniDetailsWrapperRef(this.miniDetailsWrapperRef);

    window.addEventListener('resize', this.resizeHandler);
  }

  componentWillUnmount() {
    const { backdrop, closeButton, closeDetails } = this.props;
    backdrop.removeEventListener('click', closeDetails);
    closeButton.removeEventListener('click', closeDetails);
    window.removeEventListener('resize', this.resizeHandler);
  }

  resizeHandler() {
    const { closeButton } = this.props;
    const currentIsLargeUp = mediaQueries.is_large_up();

    if (currentIsLargeUp !== this.isLargeUp) {
      this.isLargeUp = currentIsLargeUp;
      if (!currentIsLargeUp) {
        addClass('header__close--animate', closeButton);
      } else {
        removeClass('header__close--animate', closeButton);
      }
    }
  }

  render() {
    const { children } = this.props;

    return (
      <div role="alert" className="mini__details-wrapper" ref={this.miniDetailsWrapperRef}>
        <div className="mini__details" ref={this.miniDetailsRef}>
          {children}
        </div>
      </div>
    );
  }
}

MiniDetails.propTypes = {
  closeButton: PropTypes.objectOf(PropTypes.object).isRequired,
  backdrop: PropTypes.objectOf(PropTypes.object).isRequired,
  closeDetails: PropTypes.func.isRequired,
  setMiniDetailsWrapperRef: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element, PropTypes.string]).isRequired,
};

export default MiniDetails;
