import React from 'react';
import PropTypes from 'prop-types';
import MiniDetails from 'app/commonComponents/miniDetails/miniDetails';
import MiniCartIcon from './miniCartIcon/miniCartIconContainer';
import MiniCartSummary from './miniCartSummary/miniCartSummaryContainer';
import MiniCartProducts from './miniCartProducts/miniCartProductsContainer';
import MiniCartOptions from './miniCartOptions/miniCartOptionsContainer';

const MiniCartApp = ({
  isOpened,
  setMiniDetailsWrapperRef,
  miniRef,
  closeButton,
  closeOrRedirect,
  openOrRedirect,
  keyboardToggle,
  cmsComponents,
  backdrop,
  closeDetails,
  miniType,
  baseUrl,
}) => (
  <div className="nav__btn-wrapper" ref={miniRef}>
    <MiniCartIcon
      miniType={miniType}
      isOpened={isOpened}
      openOrRedirect={openOrRedirect}
      keyboardToggle={keyboardToggle}
      baseUrl={baseUrl}
    />
    {isOpened && (
      <MiniDetails
        backdrop={backdrop}
        closeButton={closeButton}
        closeDetails={closeDetails}
        setMiniDetailsWrapperRef={setMiniDetailsWrapperRef}
      >
        <MiniCartSummary closeOrRedirect={closeOrRedirect} />
        <MiniCartProducts shoppingList={cmsComponents.shoppingList} />
        <MiniCartOptions
          miniType={miniType}
          retrieveBag={cmsComponents.retrieveBag}
          closeOrRedirect={closeOrRedirect}
        />
      </MiniDetails>
    )}
  </div>
);

MiniCartApp.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  miniRef: PropTypes.objectOf(PropTypes.object).isRequired,
  miniType: PropTypes.string.isRequired,
  cmsComponents: PropTypes.objectOf(PropTypes.instanceOf(Element)).isRequired,
  closeButton: PropTypes.objectOf(PropTypes.object).isRequired,
  setMiniDetailsWrapperRef: PropTypes.func.isRequired,
  closeOrRedirect: PropTypes.func.isRequired,
  openOrRedirect: PropTypes.func.isRequired,
  keyboardToggle: PropTypes.func.isRequired,
  backdrop: PropTypes.objectOf(PropTypes.object).isRequired,
  closeDetails: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
};

export default MiniCartApp;
