import React from 'react';
import { createRoot } from 'react-dom/client';
import providerHOC from '../../utils/providerHOC/providerHOC';
import store from '../headerStore';
import AccountNameApp from './accountNameApp';
import { ACCOUNT_NAME_DESKTOP_ELEMENT, ACCOUNT_NAME_MOBILE_ELEMENT } from './accountNameConstants';

const { pageType } = window.inlineGlobalConfig;

const AccountNameDesktop = () => <AccountNameApp nameType="desktop" />;
const AccountNameMobile = () => <AccountNameApp nameType="mobile" />;

const AccountNameDesktopRoot = providerHOC(store)(AccountNameDesktop);
const AccountNameMobileRoot = providerHOC(store)(AccountNameMobile);

createRoot(ACCOUNT_NAME_DESKTOP_ELEMENT).render(<AccountNameDesktopRoot />);
createRoot(ACCOUNT_NAME_MOBILE_ELEMENT).render(<AccountNameMobileRoot />);

if (pageType === 'my-account') {
  window.addEventListener('load', () => {
    const AccountNameTitle = () => <AccountNameApp nameType="title" />;
    const AccountNameTitleRoot = providerHOC(store)(AccountNameTitle);
    createRoot(document.getElementById('accountHeaderId')).render(<AccountNameTitleRoot />);
  });
}
