import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

const SiteSelectorButton = ({ modalToggle, locale }) => {
  const handleButtonClick = () => {
    modalToggle();
  };

  return (
    <button className="site-selector__action-button" onClick={handleButtonClick} type="button">
      <FormattedMessage id="modal.siteSelector.actionButton" description="Action button for changing language" />
      &nbsp;
      <span className="site-selector__action-button-country-flag">
        <img
          src={`/assets/images/languages/lang-${locale.country}.svg`}
          width="25px"
          height="18px"
          alt={`${locale.country} flag`}
        />
      </span>
      &nbsp;
      <span className="site-selector__action-button-country">{locale.country}</span>
    </button>
  );
};

SiteSelectorButton.propTypes = {
  locale: PropTypes.objectOf(PropTypes.any).isRequired,
  modalToggle: PropTypes.func.isRequired,
};

export default SiteSelectorButton;
