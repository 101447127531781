import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { convertCurrencyToNumber } from 'app/utils/helpers';

const MiniCartOptions = ({
  entries,
  subTotalWithoutDiscounts,
  subtotal,
  totalDiscounts,
  retrieveBag,
  closeOrRedirect,
  accountIsLogged,
  miniType,
}) =>
  entries.length ? (
    <div className="mini__options">
      {convertCurrencyToNumber(totalDiscounts) ? (
        <Fragment>
          <div className="mini__options-two-column-line">
            <div>
              <FormattedMessage id="cart.bagTotal" description="Bag total" />
            </div>
            <div>{subTotalWithoutDiscounts}</div>
          </div>
          <div className="mini__options-two-column-line">
            <div>
              <FormattedMessage id="cart.discount" description="Discount" />
            </div>
            <div>-{totalDiscounts}</div>
          </div>
          <div className="mini__options-delivery">
            <FormattedMessage id="cart.checkout.info.deliveryOptions" description="Delivery options in checkout" />
          </div>
          <div className="mini__options-two-column-line">
            <div className="mini__options-subtotal ellipsis">
              <FormattedMessage id="cart.summary.subtotal" description="Subtotal" />
            </div>
            <div className="mini__options-subtotal">{subtotal}</div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className="mini__options-two-column-line">
            <div className="mini__options-subtotal ellipsis">
              <FormattedMessage id="cart.summary.subtotal" description="Subtotal" />
            </div>
            <div className="mini__options-subtotal">{subtotal}</div>
          </div>
          <div className="mini__options-delivery">
            <FormattedMessage id="cart.checkout.info.deliveryOptions" description="Delivery options in checkout" />
          </div>
        </Fragment>
      )}
      <div className={`mini__options-action mini__options-action--${miniType}`}>
        <button type="button" onClick={() => closeOrRedirect()}>
          <FormattedMessage id="checkout.checkout" description="Checkout" />
        </button>
      </div>
      <div className="mini__options-alt-action">
        <button type="button" onClick={() => closeOrRedirect()}>
          <FormattedMessage id="cart.viewOrEdit" description="View or edit bag" />
        </button>
      </div>
    </div>
  ) : (
    !accountIsLogged &&
    retrieveBag && <div className="mini__options" dangerouslySetInnerHTML={{ __html: retrieveBag.innerHTML }} />
  );

MiniCartOptions.defaultProps = {
  retrieveBag: undefined,
};

MiniCartOptions.propTypes = {
  entries: PropTypes.arrayOf(PropTypes.object).isRequired,
  subtotal: PropTypes.string.isRequired,
  subTotalWithoutDiscounts: PropTypes.string.isRequired,
  totalDiscounts: PropTypes.string.isRequired,
  closeOrRedirect: PropTypes.func.isRequired,
  accountIsLogged: PropTypes.bool.isRequired,
  miniType: PropTypes.string.isRequired,
  retrieveBag: PropTypes.instanceOf(Element),
};

export default MiniCartOptions;
