import { connect } from 'react-redux';
import MiniCartOptions from './miniCartOptions';

const mapStateToProps = ({ miniCart, loggedUserInfo }) => ({
  entries: miniCart.entries,
  subtotal: miniCart.subtotal,
  subTotalWithoutDiscounts: miniCart.subTotalWithoutDiscounts,
  totalDiscounts: miniCart.totalDiscounts,
  accountIsLogged: loggedUserInfo.accountIsLogged,
});

export default connect(mapStateToProps)(MiniCartOptions);
