import { ajaxRequest } from 'app/utils/helpers';
import { dlTrackProductDecrease, dlRemoveOneFromCart, dlRemoveAllFromCart } from 'app/analytics/addToBagAnalytics';
import { fetchCart } from 'app/cart/cartList/cartListActions';
import { showWarning, showSuccess } from 'app/globalMessages/globalMessagesClient';
import { isGuestUser } from 'app/utils/globalDataHelpers';
import {
  RECEIVE_MINI_CART,
  MINI_CART_IS_FETCHING,
  MINI_CART_ROOT_ELEMENT,
  TRANSFER_MINI_CART_REMOVE,
  TRANSFER_MINI_CART_ADD,
} from './miniCartConstants';
import { slideProductUp } from '../headerMiniUtils';

const { pageType, accountLoginUrl } = window.inlineGlobalConfig;
const { miniCartUrl, baseCartUrl, restoreCartUrl } = window.inlineCartConfiguration;

export const receiveMiniCart = (
  json = {
    id: null,
    entries: [],
    subtotal: '',
    totalPrice: '',
    totalPriceWithoutTax: '',
    totalTax: '',
    totalItems: 0,
    deliveryCost: '',
    totalDiscounts: '',
    subTotalWithoutDiscounts: '',
  }
) => ({
  type: RECEIVE_MINI_CART,
  ...json,
});

const executeAjaxRequest = (params, animateProduct) => dispatch => {
  dispatch({ type: MINI_CART_IS_FETCHING });

  return ajaxRequest(params.method, params.url, params.data, params.errorHandler, params.timestamp)
    .then(json => (animateProduct ? animateProduct(json) : json))
    .then(
      json => {
        dispatch(receiveMiniCart(json));
        return json;
      },
      () => dispatch(receiveMiniCart())
    );
};

export const fetchMiniCart = () => dispatch => {
  const ajaxParams = {
    method: 'GET',
    url: miniCartUrl,
    data: {},
    errorHandler: null,
    timestamp: false,
  };
  return dispatch(executeAjaxRequest(ajaxParams));
};

export const isCartRestored = () => {
  const currentUrl = new URL(window.location);
  const { searchParams } = currentUrl;
  const restoredGuid = searchParams.get('guid');

  if (restoredGuid) {
    return ajaxRequest('GET', `${restoreCartUrl}?guid=${restoredGuid}`).then(json => {
      const isEmptyCart = json.entries.length === 0;
      const { guid, restoreStatus, cartModifications } = json;

      if (json.restoreStatus === 'Unauthorized') {
        window.location = `${accountLoginUrl}?guid=${restoredGuid}`;
      }

      if (cartModifications.find(entry => entry.statusCode === 'noStock')) {
        const messageId = isEmptyCart ? 'cart.restore.outOfStock.emptyCart' : 'cart.restore.outOfStock.cart';
        showWarning({
          messageId,
          sticky: true,
        });
      }
      if (restoreStatus === 'OK') {
        isGuestUser().then(isGuest => {
          if (!isGuest && restoredGuid !== guid && cartModifications.length) {
            showSuccess({
              messageId: 'order.items.merged.cart.message',
              sticky: true,
            });
          }
        });
      }
      return Promise.resolve();
    });
  }
  return Promise.resolve();
};

export const fetchMiniCartOrCart = () => dispatch => {
  if (pageType === 'bag') {
    const initialFetchCart = true;
    return isCartRestored()
      .then(() => dispatch(fetchMiniCart()))
      .then(json => {
        MINI_CART_ROOT_ELEMENT.dispatchEvent(new CustomEvent(TRANSFER_MINI_CART_ADD, { detail: json }));
      })
      .then(() => dispatch(fetchCart(initialFetchCart)));
  }

  return dispatch(fetchMiniCart());
};

export const updateMiniCartItem = (entryNumber, quantity) => (dispatch, getState) => {
  if (getState().isMiniCartFetching) {
    return false;
  }

  const product = getState().miniCart.entries[entryNumber];

  const ajaxParams = {
    method: 'POST',
    url: `${baseCartUrl}/updateCartEntry`,
    data: { entryNumber, quantity },
  };

  return dispatch(executeAjaxRequest(ajaxParams)).then(response => {
    if (pageType === 'bag') {
      MINI_CART_ROOT_ELEMENT.dispatchEvent(new CustomEvent(TRANSFER_MINI_CART_REMOVE, { detail: response }));
    }

    dlTrackProductDecrease({
      product,
      location: 'mini bag',
      quantity: 1,
      cart: response,
      successFlag: true,
    });

    dlRemoveOneFromCart(product, response);

    return response;
  });
};

export const removeMiniCartItem = (entryNumber, product) => (dispatch, getState) => {
  if (getState().isMiniCartFetching) {
    return false;
  }

  const ajaxParams = {
    method: 'POST',
    url: `${baseCartUrl}/removeCartEntry`,
    data: { entryNumber },
  };

  const animateSlideUp = slideProductUp(product.id, MINI_CART_ROOT_ELEMENT);

  return dispatch(executeAjaxRequest(ajaxParams, animateSlideUp)).then(response => {
    if (pageType === 'bag') {
      MINI_CART_ROOT_ELEMENT.dispatchEvent(new CustomEvent(TRANSFER_MINI_CART_REMOVE, { detail: response }));
    }

    dlTrackProductDecrease({
      product,
      location: 'mini bag',
      quantity: product.quantity,
      cart: response,
      successFlag: true,
    });

    dlRemoveAllFromCart(product);

    return response;
  });
};
