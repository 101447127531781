import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import accountLogout from 'app/utils/logout';

const { accountPageUrl, accountLoginUrl } = window.inlineGlobalConfig;

const AccountNameHeaderMobile = ({ accountName, accountIsLogged }) =>
  accountIsLogged ? (
    <Fragment>
      <a href={accountPageUrl}>
        <FormattedMessage id="header.navigation.account.greeting" description="Hello" />
        {` ${accountName} `}
      </a>
      <button type="button" className="menu__account-tab-logout button-reset" onClick={accountLogout}>
        (
        <FormattedMessage id="header.navigation.account.logoutLink" description="Sign out" />)
      </button>
    </Fragment>
  ) : (
    <Fragment>
      <a href={`${accountLoginUrl}#login`}>
        <FormattedMessage id="header.navigation.account.loginLink" description="Login" />
      </a>
      {' | '}
      <a href={`${accountLoginUrl}#register`}>
        <FormattedMessage id="header.navigation.account.registrationLink" description="Registration" />
      </a>
    </Fragment>
  );

AccountNameHeaderMobile.defaultProps = {
  accountName: '',
};

AccountNameHeaderMobile.propTypes = {
  accountIsLogged: PropTypes.bool.isRequired,
  accountName: PropTypes.string,
};

export default AccountNameHeaderMobile;
