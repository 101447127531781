import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { usernamePadding } from 'app/utils/helpers';
import accountLogout from 'app/utils/logout';

const { accountPageUrl, accountLoginUrl } = window.inlineGlobalConfig;

function AccountNameHeader({ accountName, accountIsLogged }) {
  useEffect(() => {
    usernamePadding();
  });

  return (
    <div className="nav__account-label">
      {accountIsLogged ? (
        <Fragment>
          <a className="nav__account-label--name ellipsis" href={accountPageUrl}>
            <span data-cs-mask id="my-account-page">
              {accountName}
            </span>
          </a>
          <span className="nav__account-label--signout">
            (
            <button type="button" className="button-reset" onClick={accountLogout}>
              <FormattedMessage id="header.navigation.account.logoutLink" description="Sign out" />
            </button>
            )
          </span>
        </Fragment>
      ) : (
        <a href={accountLoginUrl} className="nav__account-label--signin ellipsis">
          <FormattedMessage id="header.navigation.account.label" description="Sign in" />
        </a>
      )}
    </div>
  );
}

AccountNameHeader.defaultProps = {
  accountName: '',
};

AccountNameHeader.propTypes = {
  accountIsLogged: PropTypes.bool.isRequired,
  accountName: PropTypes.string,
};

export default AccountNameHeader;
