import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AccountNameHeader from './components/accountNameHeader';
import AccountNameHeaderMobile from './components/accountNameHeaderMobile';
import AccountNameTitle from './components/accountNameTitle';
import { fetchUserInfo, updateAccountName } from '../../utils/loggedUserInfoHOC/loggedUserInfoActions';
import { ACCOUNT_NAME_DESKTOP_ELEMENT, TRANSFER_ACCOUNT_NAME } from './accountNameConstants';

const AccountNameApp = ({
  nameType,
  accountName,
  accountIsLogged,
  isFetching,
  fetchLoggedUserInfo,
  updateLoggedAccountName,
}) => {
  useEffect(() => {
    fetchLoggedUserInfo();

    const setLoggedAccountName = e => updateLoggedAccountName(e.detail);
    ACCOUNT_NAME_DESKTOP_ELEMENT.addEventListener(TRANSFER_ACCOUNT_NAME, setLoggedAccountName);

    return () => {
      ACCOUNT_NAME_DESKTOP_ELEMENT.removeEventListener(TRANSFER_ACCOUNT_NAME, setLoggedAccountName);
    };
  }, [fetchLoggedUserInfo, updateLoggedAccountName]);

  const NAME_TYPE_MAP = {
    desktop: () => <AccountNameHeader accountName={accountName} accountIsLogged={accountIsLogged} />,
    mobile: () => <AccountNameHeaderMobile accountName={accountName} accountIsLogged={accountIsLogged} />,
    title: () => <AccountNameTitle accountName={accountName} />,
  };

  return isFetching ? null : NAME_TYPE_MAP[nameType]();
};

AccountNameApp.defaultProps = {
  accountName: '',
};

AccountNameApp.propTypes = {
  nameType: PropTypes.string.isRequired,
  accountIsLogged: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  fetchLoggedUserInfo: PropTypes.func.isRequired,
  updateLoggedAccountName: PropTypes.func.isRequired,
  accountName: PropTypes.string,
};

const mapStateToProps = ({ loggedUserInfo }) => ({
  accountName: loggedUserInfo.accountName,
  accountIsLogged: loggedUserInfo.accountIsLogged,
  isFetching: loggedUserInfo.isFetching,
});

const mapDispatchToProps = dispatch => ({
  fetchLoggedUserInfo() {
    dispatch(fetchUserInfo());
  },
  updateLoggedAccountName(name) {
    dispatch(updateAccountName(name));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountNameApp);
