import { accordionTransitionEffect } from 'app/utils/deprecated_accordionTransition';
import mediaQueries from 'app/utils/mediaQueries';
import { debounce } from 'app/utils/helpers';
import dlTrackFooterEvents from 'app/analytics/footerEventsAnalytics';
import dlTrackMediaBannersEvents from 'app/analytics/mediaBannersEventsAnalytics';
import { getStorageData, removeStorageData } from 'app/utils/localStorage/localStorage';
import { REMOVE_FOOTER_INVISIBILITY } from './footerConstants';
import './footer.scss';

const footer = document.querySelector('.footer');
const footerAccordionTitles = document.querySelectorAll('.footer__nav-item > a');
let isLargeUp = mediaQueries.is_large_up();

const removeFooterInvisibility = getStorageData(REMOVE_FOOTER_INVISIBILITY);
if (removeFooterInvisibility) {
  footer.classList.remove('invisible');
  removeStorageData(REMOVE_FOOTER_INVISIBILITY);
}

let accordionHandler;
const isFooterDisplayed = !footer.classList.contains('invisible');
footer.classList.remove('invisible');
accordionHandler = accordionTransitionEffect(footerAccordionTitles);
if (!isFooterDisplayed) {
  footer.classList.add('invisible');
}

dlTrackFooterEvents();
dlTrackMediaBannersEvents();

// Menu height transition
function accordionFooterSwitch() {
  [].forEach.call(footerAccordionTitles, element => {
    element.setAttribute('href', `#${element.getAttribute('aria-controls')}`);
  });
  accordionHandler.add();
}

if (!mediaQueries.is_large_up()) {
  accordionFooterSwitch();
} else {
  const footerHeadings = document.querySelectorAll('.footer__nav-heading');
  const footerListing = document.querySelectorAll('.footer__nav-content');
  [].forEach.call(footerHeadings, element => {
    element.setAttribute('aria-expanded', 'true');
  });
  [].forEach.call(footerListing, element => {
    element.setAttribute('aria-hidden', 'false');
  });
}

const debounceTime = 10;
window.addEventListener(
  'resize',
  debounce(
    () => {
      if (isLargeUp !== mediaQueries.is_large_up()) {
        isLargeUp = mediaQueries.is_large_up();
        accordionHandler.remove();
        if (!isLargeUp) {
          accordionHandler = accordionTransitionEffect(footerAccordionTitles);
          accordionFooterSwitch();
        }
      }
    },
    debounceTime,
    true
  )
);
