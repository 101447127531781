import { CACHE_ITEM_BEFORE_UPDATE } from 'app/commonComponents/cartItem/cartItemConstants';
import { PROMO_STATUSES } from 'app/commonComponents/appliedCoupon/appliedCouponConstants';
import { MINI_CART_ROOT_ELEMENT, TRANSFER_MINI_CART_ADD } from 'app/header/miniCart/miniCartConstants';
import { ajaxRequest } from 'app/utils/helpers';
import { displayFooter } from 'app/utils/globalDataHelpers';
import { cartTotalItems } from '../cartUtils';
import { fetchEmptyCart } from '../emptyCart/emptyCartActions';
import { REQUEST_CART_ITEMS, RECEIVE_CART_ITEMS } from './cartListConstants';
import { CART_SOURCES } from '../cartConstants';

const { getCartUrl, updateCartItemUrl, removeCartItemUrl } = window.inlineCartConfiguration;

export const requestCartItems = () => ({
  type: REQUEST_CART_ITEMS,
});

export const receiveCartItems = (
  json = {
    appliedOrderPromotions: [],
    appliedProductPromotions: [],
    appliedVoucher: '',
    availableDeliveryModes: [],
    cartSource: '',
    deliveryAddress: null,
    deliveryCost: '',
    deliveryMode: {},
    giftWithPurchaseProducts: [],
    potentialOrderPromotions: [],
    entries: [],
    id: '',
    showTaxes: false,
    subTotal: '',
    subtotalValue: 0,
    subTotalWithoutDiscounts: '',
    subTotalWithoutDiscountsValue: 0,
    totalDiscounts: '',
    totalDiscountsValue: 0,
    totalEntries: 0,
    totalItems: 0,
    totalPrice: '',
    totalPriceValue: 0,
    totalPriceWithoutTax: 0,
    totalTax: '',
    totalTaxValue: 0,
  }
) => ({
  type: RECEIVE_CART_ITEMS,
  ...json,
  totalEntries: cartTotalItems(json),
});

export const applyPromo = () => ({
  type: PROMO_STATUSES.PROMO_APPLIED,
});

export const cacheItemBeforeUpdate = (product = {}) => ({
  type: CACHE_ITEM_BEFORE_UPDATE,
  product,
});

export const errorCallback = () => dispatch => {
  dispatch(receiveCartItems());
  dispatch(fetchEmptyCart()).then(displayFooter);
};

export const successCallback = (json, cartSource, initialFetchCart) => dispatch => {
  const appliedVoucher = json.appliedVouchers && json.appliedVouchers[0] ? json.appliedVouchers[0].voucherCode : '';
  if (appliedVoucher) {
    dispatch(applyPromo());
  }

  dispatch(
    receiveCartItems({
      appliedOrderPromotions: json.appliedOrderPromotions,
      appliedProductPromotions: json.appliedProductPromotions,
      appliedVoucher,
      availableDeliveryModes: json.availableDeliveryModes,
      cartModifications: json.cartModifications,
      cartSource,
      deliveryAddress: json.deliveryAddress,
      deliveryCost: json.deliveryCost,
      deliveryMode: json.deliveryMode,
      giftWithPurchaseProducts: json.giftWithPurchaseProducts,
      potentialOrderPromotions: json.potentialOrderPromotions,
      entries: json.entries,
      id: json.id,
      initialFetchCart,
      showTaxes: json.showTaxes,
      subTotal: json.subTotalWithoutDiscounts,
      subtotalValue: json.subtotalValue,
      subTotalWithoutDiscounts: json.subTotalWithoutDiscounts,
      subTotalWithoutDiscountsValue: json.subTotalWithoutDiscountsValue,
      totalDiscounts: json.totalDiscounts,
      totalDiscountsValue: json.totalDiscountsValue,
      totalItems: json.totalItems,
      totalPrice: json.totalPriceWithoutTax,
      totalPriceValue: json.totalPriceValue,
      totalPriceWithoutTax: json.totalPriceWithoutTax,
      totalTax: json.totalTax,
      totalTaxValue: json.totalTaxValue,
    })
  );

  displayFooter();
};

export const executeAjaxRequest = (params, cartSources, initialFetchCart) => dispatch =>
  ajaxRequest(params.method, params.url, params.data).then(
    json => {
      MINI_CART_ROOT_ELEMENT.dispatchEvent(new CustomEvent(TRANSFER_MINI_CART_ADD, { detail: json }));
      let successFlag = true;
      if (json.totalItems) {
        dispatch(successCallback(json, cartSources, initialFetchCart));
      } else {
        dispatch(errorCallback());
        successFlag = false;
      }
      return { ...json, successFlag };
    },
    () => dispatch(errorCallback())
  );

export const fetchCart =
  (initialFetchCart = false) =>
  dispatch => {
    const requestParams = {
      method: 'GET',
      url: getCartUrl,
    };
    dispatch(requestCartItems());
    return dispatch(executeAjaxRequest(requestParams, CART_SOURCES.FETCH_CART, initialFetchCart));
  };

const rejectUpdateCartPromise = () => {
  return new Promise(resolve => {
    return resolve({ error: 'cart is fetching' });
  });
};

export const updateCart = (product, quantity) => (dispatch, getState) => {
  if (getState().cart.isCartFetching) {
    return rejectUpdateCartPromise();
  }

  const entryNumber = product.position;
  const requestParams = {
    method: 'POST',
    url: updateCartItemUrl,
    data: { entryNumber, quantity },
  };

  dispatch(cacheItemBeforeUpdate(product));

  dispatch(requestCartItems());
  return dispatch(executeAjaxRequest(requestParams, CART_SOURCES.UPDATE_CART_ITEM));
};

export const removeCartItem = entryNumber => (dispatch, getState) => {
  if (getState().cart.isCartFetching) {
    return rejectUpdateCartPromise();
  }

  const requestParams = {
    method: 'POST',
    url: removeCartItemUrl,
    data: { entryNumber },
  };
  dispatch(cacheItemBeforeUpdate({}));
  dispatch(requestCartItems());
  return dispatch(executeAjaxRequest(requestParams, CART_SOURCES.REMOVE_CART_ITEM));
};
