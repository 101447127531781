import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { ValidationProcessor, ErrorsPopulator } from '@tacitknowledge/validator';
import formsHOC from 'app/formComponents/formikHOC';
import { validateBy } from 'app/formComponents/formComponentsUtils';
import { ajaxRequest, replaceKeyName } from 'app/utils/helpers';
import { dlTrackSubscriptionSubmit } from 'app/analytics/subscriptionAnalytics';
import { bvTrackSubscriptionSubmit } from 'app/analytics/bv/bvAnalytics';
import genericErrorHandler from 'app/utils/serverErrors';

const {
  emailSubscriptionValidation,
  emailSubscriptionStorageUrl,
  emailSubscriptionSuccessPage,
  newEmailSubscriptionSuccessPage,
} = window.inlineGlobalConfig;
const { subscriptionFooterCopy, subscriptionFooterCTA, subscriptionFooterLegalCopy } = window.inlineSubscriptionFooter;

const rootBEM = 'footer-subscription';
const rootMessages = 'footer.subscription';

const mappedEmailSubscriptionValidation = replaceKeyName(emailSubscriptionValidation, 'email', `${rootBEM}-email`);

const validationProcessor = new ValidationProcessor(mappedEmailSubscriptionValidation);
const errorsPopulator = new ErrorsPopulator(validationProcessor);

const validate = validateBy(validationProcessor, errorsPopulator, mappedEmailSubscriptionValidation);

const mapPropsToValues = () => ({
  [`${rootBEM}-email`]: '',
  [`${rootBEM}-women`]: false,
  [`${rootBEM}-men`]: false,
});

const footerSubscriptionHOC = WrappedComponent => {
  class FooterSubscription extends Component {
    constructor() {
      super();

      this.state = {
        responseError: null,
      };

      this.handleValidForm = this.handleValidForm.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.onSubmit = this.onSubmit.bind(this);
      this.validateGender = this.validateGender.bind(this);
      this.checkedBoxes = new Set();
      this.handleEmailChange = this.handleEmailChange.bind(this);
    }

    componentDidMount() {
      const { setFieldTouched, intl } = this.props;
      setFieldTouched(`${rootBEM}-women`, true, false);
      setFieldTouched(`${rootBEM}-men`, true, false);
      mappedEmailSubscriptionValidation.messages[`${rootBEM}-women`] = {
        required: intl.formatMessage({
          id: `${rootMessages}.gender.error`,
        }),
      };
      mappedEmailSubscriptionValidation.messagesEn[`${rootBEM}-women`] = {
        required: 'Please select what you would like to hear about',
      };
      mappedEmailSubscriptionValidation.messages[`${rootBEM}-men`] =
        mappedEmailSubscriptionValidation.messages[`${rootBEM}-women`];
      mappedEmailSubscriptionValidation.messagesEn[`${rootBEM}-men`] =
        mappedEmailSubscriptionValidation.messagesEn[`${rootBEM}-women`];
    }

    onSubmit(e) {
      e.preventDefault();

      const { submitForm } = this.props;
      submitForm().then(() => {
        const { values, errors } = this.props;

        if (isEmpty(errors)) {
          this.handleValidForm(values);
        }
      });
    }

    handleEmailChange(e) {
      this.setState({ responseError: null });
      const { handleChange } = this.props;
      handleChange(e);
    }

    handleInputChange(e) {
      const { handleChange } = this.props;
      if (e.target.checked) {
        this.checkedBoxes.add(e.target.name);
      } else {
        this.checkedBoxes.delete(e.target.name);
      }
      handleChange(e);
    }

    validateGender() {
      const { intl } = this.props;
      if (!this.checkedBoxes.size) {
        return intl.formatMessage({
          id: `${rootMessages}.gender.error`,
        });
      }
      return null;
    }

    handleValidForm(formData) {
      const { intl } = this.props;
      const formattedFormData = {
        email: formData[`${rootBEM}-email`],
        newsletters: {
          men: formData[`${rootBEM}-men`],
          women: formData[`${rootBEM}-women`],
        },
      };

      if (this.checkedBoxes.size) {
        const gender =
          this.checkedBoxes.size === 2
            ? 'women/men'
            : Object.keys(formattedFormData.newsletters).find(key => formattedFormData.newsletters[key]);

        ajaxRequest('POST', emailSubscriptionStorageUrl, formattedFormData).then(
          res => {
            if (res.couponValid) {
              dlTrackSubscriptionSubmit(gender);
              bvTrackSubscriptionSubmit();
              window.location = emailSubscriptionSuccessPage;
            } else if (!res.couponValid && res.success) {
              dlTrackSubscriptionSubmit(gender);
              bvTrackSubscriptionSubmit();
              window.location = newEmailSubscriptionSuccessPage;
            } else if (!res.couponValid && !res.success) {
              const message = intl.formatMessage({
                id: 'security.ui.auth.marketing.newsletters.already.subscribed.and.ordered',
              });
              this.setState({
                responseError: message,
              });
            }
          },
          ({ status }) => genericErrorHandler(status)
        );
      }
    }

    render() {
      const { errors } = this.props;
      const { responseError } = this.state;

      return (
        <section aria-labelledby="newsletter-call">
          <legend
            id="newsletter-call"
            className="h3 newsletter__call"
            dangerouslySetInnerHTML={{ __html: subscriptionFooterCopy }}
          />
          <WrappedComponent
            handleInputChange={this.handleInputChange}
            onSubmit={this.onSubmit}
            rootBEM={rootBEM}
            rootMessages={rootMessages}
            buttonText={subscriptionFooterCTA}
            formName="footerSubscription"
            validateGender={this.validateGender}
            errors={errors}
            responseError={responseError}
            handleEmailChange={this.handleEmailChange}
          />
          <div className="newsletter__notice">
            <p dangerouslySetInnerHTML={{ __html: subscriptionFooterLegalCopy }} />
          </div>
        </section>
      );
    }
  }

  FooterSubscription.propTypes = {
    errors: PropTypes.objectOf(PropTypes.string).isRequired,
    values: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])).isRequired,
    submitForm: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  };

  return FooterSubscription;
};

export default component =>
  formsHOC({
    mapPropsToValues,
    validate,
    validations: mappedEmailSubscriptionValidation,
    formName: 'footerSubscription-emailForm',
  })(injectIntl(footerSubscriptionHOC(component)));
