import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const MiniCartSummary = ({ totalItems, subtotal, closeOrRedirect }) => (
  <div className={`mini__summary ${!totalItems ? 'mini__summary--empty' : ''}`}>
    {totalItems ? (
      <div className="mini__summary-wrapper">
        <div className="mini__summary-info">
          <p>
            <strong>{totalItems}</strong>{' '}
            {totalItems === 1 ? (
              <FormattedMessage id="cart.summary.count.item" description="Item" />
            ) : (
              <FormattedMessage id="cart.summary.count.items" description="Items" />
            )}
          </p>
          <p className="mini__summary-info-bold">
            <FormattedMessage id="cart.summary.subtotal" description="Subtotal" />
            &nbsp;
            <span className="price">{subtotal}</span>
          </p>
        </div>
        <div className="mini__summary-button">
          <button type="button" onClick={() => closeOrRedirect()}>
            <FormattedMessage id="checkout.checkout" description="Checkout" />
          </button>
        </div>
      </div>
    ) : (
      <p className="mini__summary-empty-bag">
        <FormattedMessage id="cart.emptyBag" description="Empty bag" />
      </p>
    )}
  </div>
);

MiniCartSummary.propTypes = {
  totalItems: PropTypes.number.isRequired,
  subtotal: PropTypes.string.isRequired,
  closeOrRedirect: PropTypes.func.isRequired,
};

export default MiniCartSummary;
